import React from 'react';
import cn from 'classnames';
import { PageCategoryGroup } from '@livewire/toolkit/components';
import { SVG } from '@livewire/common/components';
import { useApp, usePageNavigation } from '@livewire/toolkit/hooks';
import { Link } from 'gatsby';
import { TCategory } from 'src/hooks/usePageNavigation';
import * as styles from './styles.module.scss';

const Header = () => {
  const { isHeaderOpen, setIsHeaderOpen } = useApp();
  const { pageCategories } = usePageNavigation();

  return (
    <>
      <div
        onClick={() => setIsHeaderOpen(false)}
        aria-hidden
        className={cn(styles.bgOverlay, {
          [styles.visible]: isHeaderOpen
        })}
      />

      <header
        className={cn(styles.header, {
          [styles.open]: isHeaderOpen
        })}
      >
        <Link to="/" className={styles.homeLink}>
          <SVG svg="wordmark" className={styles.wordmark} />
        </Link>
        <button
          onClick={() => setIsHeaderOpen((prev) => !prev)}
          type="button"
          className={cn(styles.hamburger, { [styles.open]: isHeaderOpen })}
        >
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </button>
      </header>

      <div
        className={cn(styles.navContainer, {
          [styles.open]: isHeaderOpen
        })}
      >
        <nav className={styles.nav}>
          <div className={styles.navItems}>
            {pageCategories?.map((category: TCategory) => (
              <div key={category.id} className={styles.categoryGroup}>
                <PageCategoryGroup
                  pages={category.pages}
                  title={category.title}
                />
              </div>
            ))}
          </div>
        </nav>
      </div>

      <div className={styles.contentPadding} />
    </>
  );
};

export default Header;
