// extracted by mini-css-extract-plugin
export var container = "styles-module--container--d3930";
export var desktop = "1340px";
export var example = "styles-module--example--48875";
export var giant = "2200px";
export var header = "styles-module--header--4fa9c";
export var headerText = "styles-module--headerText--4d1bb";
export var info = "styles-module--info--32791";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var row = "styles-module--row--92632";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var type = "styles-module--type--dadaf";
export var typeContainer = "styles-module--typeContainer--2779f";