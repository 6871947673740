import React from 'react';
import { useInView } from 'react-intersection-observer';
import { IColorPairGrid } from '@livewire/sanity';
import { ColorGridTile, SliceConfig } from '@livewire/toolkit/components';
import { IntersectionAnimation } from '@livewire/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IColorPairGrid;
}

export interface INewColorDetails {
  title: string;
  hex: string;
}

const ColorPairGrid = ({ data: { colorPairs, sliceConfig } }: IProps) => {
  const ANIMATION_STAGGER = 150;

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <SliceConfig config={sliceConfig}>
      <div ref={ref}>
        {colorPairs.map(({ color1, color2 }, i) => (
          <div className={styles.tilePair} key={i}>
            <IntersectionAnimation
              animation="fadeGrow"
              trigger={inView}
              delay={ANIMATION_STAGGER * (i * 2)}
            >
              <ColorGridTile
                title={color1.title}
                hex={color1.hex}
                textColor={color2.hex}
                pantone={color1.pantone}
              />
            </IntersectionAnimation>

            <IntersectionAnimation
              animation="fadeGrow"
              trigger={inView}
              delay={ANIMATION_STAGGER * (i * 2 + 1)}
            >
              <ColorGridTile
                title={color2.title}
                hex={color2.hex}
                textColor={color1.hex}
                pantone={color2.pantone}
              />
            </IntersectionAnimation>
          </div>
        ))}
      </div>
    </SliceConfig>
  );
};

export default ColorPairGrid;
