import React from 'react';
import cn from 'classnames';
import {
  Theme,
  Header,
  Notification,
  Footer,
  PageNavButtons
} from '@livewire/toolkit/components';
import { NoJs } from '@livewire/common/components';
import * as styles from './styles.module.scss';
import 'modern-normalize/modern-normalize.css';
import '@livewire/common/styles/global.css';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => (
  <>
    <NoJs />
    <Theme />
    <Header />
    <div className={cn(styles.container, className)}>{children}</div>
    {/* <PageNavButtons /> */}
    <Footer />
    <Notification />
  </>
);

export default Layout;
