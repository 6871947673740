import React from 'react';
import cn from 'classnames';
import { SVG } from '@livewire/common/components';
import * as styles from './styles.module.scss';

export interface IEditableTextField {
  value: string;
  error?: boolean;
  onChange?: (value: string) => void;
  isEditing?: boolean;
  className?: string;
  isResetEnabled?: boolean;
  onReset?: () => void;
  style?: React.CSSProperties;
}

const EditableTextField = ({
  isEditing,
  value,
  onChange,
  error,
  className = `b2`,
  isResetEnabled,
  onReset,
  style
}: IEditableTextField) => {
  const showResetButton = isResetEnabled && isEditing;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange && onChange(newValue);
  };

  return (
    <div className={styles.container} style={style}>
      <input
        disabled={!isEditing}
        type="text"
        value={value}
        onChange={handleChange}
        className={cn(className, styles.input, { [styles.error]: error })}
      />

      <button
        disabled={!showResetButton}
        onClick={onReset}
        className={styles.resetButton}
      >
        <SVG svg="undo" className={styles.resetIcon} />
      </button>
    </div>
  );
};

export default EditableTextField;
