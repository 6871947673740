import React, { ReactNode } from 'react';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { SVG, TSvg } from '@livewire/common/components';

interface IHeaderButton {
  icon: TSvg;
  onClick: () => void;
  labelText: string;
}

interface IProps {
  title: string;
  children: ReactNode;
  className?: string;
  button?: IHeaderButton;
}

const PlaygroundSection = ({ children, title, className, button }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.titleContainer}>
        <h2 className={cn(`h3`)}>{title}</h2>
        {button && (
          <button
            className={styles.button}
            type="button"
            onClick={button.onClick}
          >
            <SVG svg={button.icon} className={styles.buttonIcon} />
          </button>
        )}
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PlaygroundSection;
