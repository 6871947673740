import { graphql, useStaticQuery } from 'gatsby';
import { useApp } from '@livewire/toolkit/hooks';
import { TToolkitPageColorScheme, IToolkitPage } from '@livewire/sanity';

interface IUsePageColorScheme {
  colorScheme: TToolkitPageColorScheme;
  backgroundColor: string;
  textColor: string;
}

export const usePageColorScheme: () => IUsePageColorScheme = () => {
  const { pathname } = useApp();

  const data = useStaticQuery(graphql`
    query ColorSchemeQuery {
      allSanityToolkitPage {
        nodes {
          slug {
            current
          }
          colorScheme
        }
      }
    }
  `);

  const fallback: TToolkitPageColorScheme = 'white on onyx';

  const pageNodes: IToolkitPage[] = data.allSanityToolkitPage.nodes;

  const currentPageSlug = pathname.split(`/`)[1];

  const matchingPageNode = pageNodes.find(
    (node) => node.slug.current === currentPageSlug
  );

  const colorScheme = matchingPageNode?.colorScheme || fallback;

  const textColorName = colorScheme.split(' on ')[0];
  const backgroundColorName = colorScheme.split(' on ')[1];

  const textColor = `var(--color-${textColorName})`;
  const backgroundColor = `var(--color-${backgroundColorName})`;

  return {
    colorScheme,
    backgroundColor,
    textColor
  };
};

export default usePageColorScheme;
