// extracted by mini-css-extract-plugin
export var container = "styles-module--container--a4896";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--041bc";
export var links = "styles-module--links--c32fe";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--0fba8";