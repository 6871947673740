import React from 'react';
import { Grid } from '@livewire/common/components';
import { SliceConfig } from '@livewire/toolkit/components';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const Footer = () => {
  return (
    <SliceConfig
      config={{
        backgroundColor: { hex: 'var(--color-onyx)' },
        textColor: { hex: 'var(--color-white)' }
      }}
    >
      <Grid>
        <div className={styles.container}>
          <div className={cn('label', styles.bottomText)}>
            <p>© Livewire {new Date().getFullYear()}</p>
            <p>
              Made with{' '}
              <a
                className={styles.lamLink}
                href="https://www.loveandmoney.agency/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Love + Money
              </a>
            </p>
          </div>
        </div>
      </Grid>
    </SliceConfig>
  );
};

export default Footer;
