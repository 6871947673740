import PlaygroundWrapper from "./PlaygroundWrapper";
import type { TInspectorDirectory } from "./PlaygroundWrapper";

// Components
import PgButton from "./pgComponents/PgButton";
import PgTextInput from "./pgComponents/PgTextInput";

const PGComponents = Object.freeze({
  button: PgButton,
  textInput: PgTextInput
});

type TPGComponent = keyof typeof PGComponents;

// Slices
import PgHero from "./pgSlices/PgHero";

const PGSlices = Object.freeze({
  hero: PgHero
});

type TPGSlice = keyof typeof PGSlices;

export {
  PlaygroundWrapper,
  TInspectorDirectory,
  PGComponents,
  PGSlices,
  type TPGComponent,
  type TPGSlice
};
