import React from 'react';
import { SliceConfig } from '@livewire/toolkit/components';
import {
  IntersectionAnimation,
  PortableTextRenderer
} from '@livewire/common/components';
import { ITextColumns } from '@livewire/sanity';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITextColumns;
}

const TextColumns = ({ data: { sliceConfig, textColumns } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const isThreeColumnLayout = textColumns.length === 3;

  return (
    <div
      ref={ref}
      className={cn(styles.grid, {
        [styles.threeColumns]: isThreeColumnLayout
      })}
    >
      {textColumns.map((column, i) => (
        <div className={cn(styles.column)} key={i}>
          <SliceConfig config={sliceConfig}>
            <IntersectionAnimation trigger={inView} delay={i * 150}>
              <div className={styles.content}>
                <PortableTextRenderer
                  className={styles.leftColumn}
                  rawText={column._rawLeftColumn}
                />
                <PortableTextRenderer rawText={column._rawRightColumn} />
              </div>
            </IntersectionAnimation>
          </SliceConfig>
        </div>
      ))}
    </div>
  );
};

export default TextColumns;
