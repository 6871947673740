// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--53379";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mediaItemContainer = "styles-module--mediaItemContainer--ecb5d";
export var mobile = "400px";
export var oneColumnLayout = "styles-module--oneColumnLayout--9e64c";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var threeColumnLayout = "styles-module--threeColumnLayout--65801";
export var twoColumnLayout = "styles-module--twoColumnLayout--e99dd";
export var video = "styles-module--video--ee4ad";