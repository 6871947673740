import React from 'react';
import { Helmet } from 'react-helmet';
import getFontFamilyCss from './getFontFamilyCss';
import { useASG } from '@livewire/toolkit/hooks';
import getTypestyleCss from './getTypestyleCss';

/** ============================================================================
 * @component
 * Inject the formatted CSS into the document head
 */
const Fonts = () => {
  const { fonts, typestyles } = useASG();

  return (
    <Helmet>
      <style>{getFontFamilyCss(fonts)}</style>
      <style>{getTypestyleCss(typestyles)}</style>
    </Helmet>
  );
};

export default Fonts;
