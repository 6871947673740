import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import cn from 'classnames';
import { IVariableType } from '@livewire/sanity';
import { SliceConfig } from '@livewire/toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IVariableType;
}

const VariableType = ({
  data: { sliceConfig, heading, description, word }
}: IProps) => {
  const characterRefs = Array.from({ length: 6 }, () => useRef(null));

  const [fontScale, setFontScale] = useState(1);

  const fontVariationSettings = [
    {
      wght: 600,
      wdth: 24,
      ital: 0
    },
    {
      wght: 700,
      wdth: 48,
      ital: 0
    },
    {
      wght: 700,
      wdth: 72,
      ital: 0
    },
    {
      wght: 800,
      wdth: 96,
      ital: 0
    },
    {
      wght: 800,
      wdth: 108,
      ital: 0
    },
    {
      wght: 900,
      wdth: 124,
      ital: 0
    }
  ];

  useEffect(() => {
    const handleMouseMove = (event) => {
      const clientX = event.clientX;

      // Calculate fontScale based on mouse position
      setFontScale((0.5 + clientX / window.innerWidth) * 1.25);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (!characterRefs?.[0]) {
      return;
    }

    gsap.killTweensOf(characterRefs);

    characterRefs.forEach((charRef, index) => {
      gsap.to(charRef.current, {
        '--font-width': parseInt(fontVariationSettings[index].wdth * fontScale),
        '--font-weight': parseInt(
          fontVariationSettings[index].wght * fontScale
        ),
        duration: 0.5,
        ease: `power2.out`
      });
    });
  }, [characterRefs, fontScale]);

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <article className={styles.row}>
          <div className={styles.description}>
            <p className="b2">{heading}</p>

            <p className="b2">{description}</p>
          </div>

          <div className={styles.typeContainer}>
            <h2 className={cn(styles.type, `d1`)}>
              {(word?.toLowerCase() === `change` && (
                <>
                  {`CHANGE`.split('').map((character, index) => {
                    return (
                      <span
                        ref={characterRefs[index]}
                        className={cn(styles[`type${character.toUpperCase()}`])}
                        style={{
                          '--font-width': fontVariationSettings[index].wdth,
                          '--font-weight': fontVariationSettings[index].wght
                        }}
                      >
                        {character}
                      </span>
                    );
                  })}
                </>
              )) || (
                <>
                  <span className={cn(styles[`type${heading.toUpperCase()}`])}>
                    {word}
                  </span>
                </>
              )}
            </h2>
          </div>
        </article>
      </div>
    </SliceConfig>
  );
};

export default VariableType;
