import React from 'react';
import * as styles from './styles.module.scss';
import { SliceConfig } from '@livewire/toolkit/components';
import { IFonts } from '@livewire/sanity';
import cn from 'classnames';
import { useASG } from '@livewire/toolkit/hooks';
import FontItem from './components/FontItem';
import CreateNewFont from './components/CreateNewFont';

interface IProps {
  data: IFonts;
}

export type TActiveBreakpoint = `desktop` | `tablet` | `mobile`;

export interface INewFontDetails {
  title: string;
  fontWeight: string;
  woff2: File;
  woff: File;
}

const Fonts = ({ data: { sliceConfig } }: IProps) => {
  const { fonts } = useASG();

  return (
    <SliceConfig config={sliceConfig}>
      <h2 className={cn(`h1`, styles.title)}>Fonts</h2>

      <div className={styles.items}>
        {fonts.map((font) => (
          <FontItem key={font._id} font={font} />
        ))}

        <CreateNewFont />
      </div>
    </SliceConfig>
  );
};

export default Fonts;
