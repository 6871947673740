import React from 'react';
import { SliceConfig } from '@livewire/toolkit/components';
import {
  LayoutProvider,
  PortableTextRenderer
} from '@livewire/common/components';
import { ITypePreview } from '@livewire/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITypePreview;
}

const TypePreview = ({
  data: {
    _rawContent,
    _rawContentLeft,
    _rawContentRight,
    properties,
    style,
    sliceConfig
  }
}: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <LayoutProvider gridClassName={styles.container} grid>
        <div className={styles.properties}>
          <h4 className={cn(styles.styleTitle, `b2`)}>{style}</h4>

          <div className={cn(styles.properties, `b2`)}>{properties}</div>
        </div>

        <div className={styles.columnLeft}>
          <PortableTextRenderer
            rawText={_rawContentLeft}
            className={styles.content}
          />
        </div>

        <div className={styles.columnRight}>
          <PortableTextRenderer
            rawText={_rawContentRight}
            className={styles.content}
          />
        </div>
      </LayoutProvider>
    </SliceConfig>
  );
};

export default TypePreview;
