import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { IVideoSlider } from '@livewire/sanity';
import { SliceConfig } from '@livewire/toolkit/components';
import { IntersectionAnimation } from '@livewire/common/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: IVideoSlider;
}

const VideoSlider = ({
  data: { sliceConfig, video, leftHeading, leftTags, rightHeading, rightTags }
}: IProps) => {
  const [videoProgress, setVideoProgress] = useState(0);
  const [dragLabelContainerWidth, setDragLabelContainerWidth] = useState(0);

  const videoRef = useRef<HTMLVideoElement>(null);
  const dragLabelContainerRef = useRef<HTMLDivElement>(null);

  const dragLabelWidth = 50; // sync with styles.module.scss
  const labelWidthPercentage = (dragLabelWidth / dragLabelContainerWidth) * 100;

  const adjustedProgress = videoProgress * (1 - labelWidthPercentage / 100);

  const calculateDragLabelContainerWidth = () => {
    if (!dragLabelContainerRef.current) return;
    setDragLabelContainerWidth(dragLabelContainerRef.current.offsetWidth);
  };

  useEffect(() => {
    calculateDragLabelContainerWidth();
    window.addEventListener(`resize`, calculateDragLabelContainerWidth);
    return () =>
      window.removeEventListener(`resize`, calculateDragLabelContainerWidth);
  });

  useEffect(() => {
    if (!videoRef.current || !videoRef.current.duration) return;
    videoRef.current.currentTime =
      videoRef.current.duration * (videoProgress / 100);
  }, [videoProgress]);

  return (
    <SliceConfig config={sliceConfig}>
      <IntersectionAnimation>
        <div className={styles.topContent}>
          <div>
            <div className={cn('h4')}>{leftHeading}</div>

            {leftTags?.[0] && (
              <div className={styles.tags}>
                {leftTags.map((tag, i) => (
                  <div key={i} className={styles.tag}>
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.rightContent}>
            <div className={cn('h4')}>{rightHeading}</div>

            {rightTags?.[0] && (
              <div className={styles.tags}>
                {rightTags.map((tag, i) => (
                  <div key={i} className={styles.tag}>
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={styles.sliderContainer}>
          <input
            type="range"
            min="0"
            max="100"
            value={videoProgress}
            onChange={(e) => setVideoProgress(e.target.valueAsNumber)}
            className={styles.slider}
          />

          <div
            className={styles.dragLabelContainer}
            ref={dragLabelContainerRef}
          >
            <div
              style={{ transform: `translateX(${adjustedProgress}%)` }}
              className={cn('label', styles.dragLabel)}
            >
              Drag
            </div>
          </div>
        </div>

        <video ref={videoRef} className={styles.video} src={video.asset.url} />
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default VideoSlider;
