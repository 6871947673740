import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { TCase } from '../..';
import * as styles from './styles.module.scss';

interface IProps {
  letterCase: TCase;
  setLetterCase: React.Dispatch<React.SetStateAction<TCase>>;
  className?: string;
}

interface IPillBackgroundStyles {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

const ToggleButtons = ({ letterCase, setLetterCase, className }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const uppercaseButtonRef = useRef<HTMLButtonElement>(null);
  const lowercaseButtonRef = useRef<HTMLButtonElement>(null);

  const getPillBackgroundStyles = useCallback(() => {
    if (
      !uppercaseButtonRef.current ||
      !lowercaseButtonRef.current ||
      !containerRef.current
    )
      return {};

    if (letterCase === `uppercase`) {
      const left = uppercaseButtonRef.current.offsetLeft;
      const top = uppercaseButtonRef.current.offsetTop;
      const width = uppercaseButtonRef.current.offsetWidth;
      const height = uppercaseButtonRef.current.offsetHeight;

      return {
        height,
        left,
        top,
        width
      };
    }

    if (letterCase === `lowercase`) {
      const left = lowercaseButtonRef.current.offsetLeft;
      const top = lowercaseButtonRef.current.offsetTop;
      const width = lowercaseButtonRef.current.offsetWidth;
      const height = lowercaseButtonRef.current.offsetHeight;

      return {
        height,
        left,
        top,
        width
      };
    }

    return {};
  }, [letterCase]);

  const [pillBackgroundStyles, setPillBackgroundStyles] =
    useState<IPillBackgroundStyles>(getPillBackgroundStyles());

  useEffect(() => {
    setPillBackgroundStyles(getPillBackgroundStyles());
  }, [letterCase, getPillBackgroundStyles]);

  return (
    <div ref={containerRef} className={cn(styles.container, className)}>
      <div className={styles.pillBackground} style={pillBackgroundStyles} />
      <button
        ref={uppercaseButtonRef}
        type="button"
        className={cn(styles.button, {
          [styles.active]: letterCase === `uppercase`
        })}
        onClick={() => setLetterCase(`uppercase`)}
      >
        Uppercase
      </button>
      <button
        ref={lowercaseButtonRef}
        type="button"
        className={cn(styles.button, {
          [styles.active]: letterCase === `lowercase`
        })}
        onClick={() => setLetterCase(`lowercase`)}
      >
        Lowercase
      </button>
    </div>
  );
};

export default ToggleButtons;
