import React from 'react';
import { SliceConfig } from '@livewire/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider,
  PortableTextRenderer,
  UniversalLink
} from '@livewire/common/components';
import { IFeaturedText } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IFeaturedText;
}

const FeaturedText = ({ data: { links, _rawText, sliceConfig } }: IProps) => (
  <SliceConfig config={sliceConfig}>
    <LayoutProvider grid>
      <div className={styles.content}>
        <IntersectionAnimation>
          <PortableTextRenderer rawText={_rawText} />
        </IntersectionAnimation>

        {links?.[0] && (
          <IntersectionAnimation delay={150}>
            <div className={styles.links}>
              {links?.map((link, i) => (
                <UniversalLink link={link} key={i} theme="lightOnDark" />
              ))}
            </div>
          </IntersectionAnimation>
        )}
      </div>
    </LayoutProvider>
  </SliceConfig>
);

export default FeaturedText;
