import React from 'react';
import cn from 'classnames';
import { hexToRGB, rgbToCMYK } from '@livewire/common/utils';
import * as styles from './styles.module.scss';

interface IProps {
  title: string;
  hex: string;
  textColor?: string;
  pantone?: string;
}

const ColorGridTile = ({
  hex,
  title,
  pantone,
  textColor = 'var(--color-black)'
}: IProps) => {
  const rgb = hexToRGB(hex);
  const { red, green, blue } = rgb;

  const { c, m, y, k } = rgbToCMYK(rgb);

  return (
    <div className={styles.container}>
      <div
        className={styles.content}
        style={{ color: textColor, background: hex }}
      >
        <h3 className={cn('h4', styles.title)}>{title}</h3>
        <div className={cn('b2', styles.bottomText)}>
          <div className={styles.bottomTextEdge}>
            <div>{hex}</div>
            <div className={styles.rgb}>
              <span>R {red}</span>
              <span>G {green}</span>
              <span>B {blue}</span>
            </div>
          </div>

          <div className={styles.bottomRightEdge}>
            <div>
              C {c} M {m} Y {y} K {k}
            </div>
            {pantone && <div>{pantone}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorGridTile;
