import React, { useState } from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { ITypeGrid } from '@livewire/sanity';
import { SliceConfig } from '@livewire/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@livewire/common/components';
import ToggleButtons from './components/ToggleButtons';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITypeGrid;
}

export type TCase = 'uppercase' | 'lowercase';

const TypeGrid = ({ data: { sliceConfig } }: IProps) => {
  const [letterCase, setLetterCase] = useState<TCase>('uppercase');

  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789♡+$←→™'.split('');

  const { ref, inView } = useInView({
    rootMargin: '-90px'
  });

  return (
    <SliceConfig config={sliceConfig}>
      <LayoutProvider grid>
        <div className={styles.container}>
          <ToggleButtons
            className={styles.toggleButtons}
            letterCase={letterCase}
            setLetterCase={setLetterCase}
          />

          <div
            ref={ref}
            className={cn(styles.grid, {
              [styles.uppercase]: letterCase === 'uppercase'
            })}
          >
            {characters.map((character, i) => (
              <IntersectionAnimation
                className={styles.gridItem}
                trigger={inView}
                delay={i * 50}
                animation="fadeGrow"
                key={i}
              >
                <div className={cn('h1', styles.gridItemContent)}>
                  {character}
                </div>
              </IntersectionAnimation>
            ))}
          </div>
        </div>
      </LayoutProvider>
    </SliceConfig>
  );
};

export default TypeGrid;
