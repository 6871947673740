import { ITypestyle } from '@livewire/sanity';
import { useASG } from '@livewire/toolkit/hooks';

const getTypestyleCss = (typestyles: ITypestyle[] = []) => {
  const MIN_TABLET_SIZE = 768;
  const MIN_DESKTOP_SIZE = 1024;

  const { getFontById } = useASG();

  let css = ``;

  typestyles.forEach((typestyle) => {
    const font = getFontById(typestyle.font._id);

    if (!font) {
      return;
    }

    css += `
				.${typestyle.className} {
					font-family: "${font?.title}";
					font-size: ${typestyle.fontSizes.mobile};
					line-height: ${typestyle.fontSizes.mobileLineHeight};
					letter-spacing: ${typestyle.fontSizes.mobileLetterSpacing};
					text-transform: ${typestyle.uppercase ? 'uppercase' : 'none'};
					text-decoration: ${typestyle.underline ? 'underline' : 'none'};

          ${
            typestyle?.fontVariationSettings &&
            `font-variation-settings: ${typestyle.fontVariationSettings};`
          }
				}
				@media (min-width: ${MIN_TABLET_SIZE}px) {
					.${typestyle.className} {
            line-height: ${typestyle.fontSizes.tabletLineHeight};
            letter-spacing: ${typestyle.fontSizes.tabletLetterSpacing};
						font-size: ${typestyle.fontSizes.tablet};
					}
				}
				@media (min-width: ${MIN_DESKTOP_SIZE}px) {
					.${typestyle.className} {
            line-height: ${typestyle.fontSizes.desktopLineHeight};
            letter-spacing: ${typestyle.fontSizes.desktopLetterSpacing};
						font-size: ${typestyle.fontSizes.desktop};
					}
				}
			`;
  });

  return css;
};

export default getTypestyleCss;
