// extracted by mini-css-extract-plugin
export var container = "styles-module--container--f50bb";
export var description = "styles-module--description--e3e9d";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var row = "styles-module--row--78e0d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var type = "styles-module--type--759d6";
export var typeA = "styles-module--typeA--eb2c2";
export var typeBOLD = "styles-module--typeBOLD--17643";
export var typeC = "styles-module--typeC--1bca7";
export var typeContainer = "styles-module--typeContainer--7c363";
export var typeDYNAMIC = "styles-module--typeDYNAMIC--ecb5e";
export var typeE = "styles-module--typeE--bf89a";
export var typeG = "styles-module--typeG--7b9a7";
export var typeH = "styles-module--typeH--14712";
export var typeN = "styles-module--typeN--e9f36";