import React from 'react';
import { SliceConfig } from '@livewire/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@livewire/common/components';
import { IHero } from '@livewire/sanity';
import * as styles from './styles.module.scss';

interface IProps {
  data: IHero;
}

const Hero = ({ data: { title, sliceConfig, image, caption } }: IProps) => {
  return (
    <div className={styles.container}>
      <SliceConfig
        config={{
          ...sliceConfig,
          backgroundColor: {
            hex: 'var(--theme-bg-color)'
          },
          textColor: {
            hex: 'var(--theme-text-color)'
          }
        }}
      >
        <LayoutProvider grid>
          <div className={styles.textContainer}>
            {caption && (
              <IntersectionAnimation>
                <h1 className="b2">{caption}</h1>
              </IntersectionAnimation>
            )}

            <IntersectionAnimation>
              <h1 className="d1">{title}</h1>
            </IntersectionAnimation>

            <div></div>
          </div>
        </LayoutProvider>
      </SliceConfig>
    </div>
  );
};

export default Hero;
