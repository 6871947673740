// extracted by mini-css-extract-plugin
export var columnLeft = "styles-module--columnLeft--00afb";
export var columnRight = "styles-module--columnRight--0e7d2";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var properties = "styles-module--properties--5b610";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";