import React from 'react';
import cn from 'classnames';
import { ITypeWeights } from '@livewire/sanity';
import { Grid } from '@livewire/common/components';
import { SliceConfig } from '@livewire/toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  data: ITypeWeights;
}

const TypeWeights = ({ data: { sliceConfig, heading, weights } }: IProps) => {
  let i;

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <header className={styles.header}>
          <Grid>
            <div className={styles.headerText}>
              <p className="h1">{heading}</p>
            </div>
          </Grid>
        </header>

        {weights.map(
          ({ name, attributes, exampleText, fontVariationSettings }) => {
            return (
              <article className={styles.row}>
                <header className={styles.info}>
                  <div>
                    <h4 className="b1">{name}</h4>
                    <p className="caption">{fontVariationSettings}</p>
                  </div>

                  {/* <br /> */}

                  {/* <p className="b1">{attributes}</p> */}
                </header>

                <div className={styles.typeContainer}>
                  <h2
                    className={cn(styles.example, `d1`)}
                    style={{ fontVariationSettings }}
                  >
                    {exampleText}
                  </h2>
                </div>
              </article>
            );
          }
        )}
      </div>
    </SliceConfig>
  );
};

export default TypeWeights;
