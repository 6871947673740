// extracted by mini-css-extract-plugin
export var active = "styles-module--active--5cd22";
export var clickLabel = "styles-module--clickLabel--6f80d";
export var container = "styles-module--container--53329";
export var content = "styles-module--content--04081";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mounted = "styles-module--mounted--44887";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tag = "styles-module--tag--03bc8";
export var tags = "styles-module--tags--5ea3e";
export var title = "styles-module--title--ff083";
export var titleContainer = "styles-module--titleContainer--88836";