import React from 'react';
import { IResourceLink } from '@livewire/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { Button } from '@livewire/common/components';

interface IProps extends IResourceLink {
  className?: string;
}

const ResourceLink = ({ title, buttonText, url, className }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(`h3`, styles.title)}>{title}</div>
      <div className={styles.border} />
      <Button href={url}>{buttonText}</Button>
    </div>
  );
};

export default ResourceLink;
