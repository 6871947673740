import * as React from "react";
import AppProvider from "./src/context/AppContext";
import ASGProvider from "./src/context/ASGContext";

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <ASGProvider>
      {element}
    </ASGProvider>
  </AppProvider>
);
