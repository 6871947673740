import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { IColor, IFont, ITypestyle } from '@livewire/sanity';

export interface IASGContext {
  colors: IColor[];
  setColors: React.Dispatch<React.SetStateAction<IColor[]>>;
  typestyles: ITypestyle[];
  setTypestyles: React.Dispatch<React.SetStateAction<ITypestyle[]>>;
  fonts: IFont[];
  setFonts: React.Dispatch<React.SetStateAction<IFont[]>>;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  getFontById: (id: string) => IFont | undefined;
}

export const ASGContext = createContext<IASGContext>({} as IASGContext);

interface IProps {
  children: React.ReactNode;
}

const ASGProvider = ({ children }: IProps) => {
  // Todo - this will need to be sourced with API client so changes will show instantly if app is refreshed
  const asgQuery = useStaticQuery(graphql`
    query {
      allSanityTypestyle(sort: { orderRank: ASC }) {
        nodes {
          title
          uppercase
          fontVariationSettings

          fontSizes {
            mobile
            mobileLineHeight
            mobileLetterSpacing

            tablet
            tabletLineHeight
            tabletLetterSpacing

            desktop
            desktopLineHeight
            desktopLetterSpacing
          }
          font {
            _id
            fontWeight
          }
          className
          _id
        }
      }
      allSanityColor(sort: { orderRank: ASC }) {
        nodes {
          _id
          title
          hex
          category
          display
          slug {
            current
          }
        }
      }
      allSanityFont(sort: { orderRank: ASC }) {
        nodes {
          _id
          woff2 {
            asset {
              originalFilename
              url
              _id
            }
          }
          woff {
            asset {
              originalFilename
              url
              _id
            }
          }
          title
          fontWeight
        }
      }
    }
  `);

  const allColors: IColor[] = asgQuery.allSanityColor.nodes;
  const allTypestyles: ITypestyle[] = asgQuery.allSanityTypestyle.nodes;
  const allFonts: IFont[] = asgQuery.allSanityFont.nodes;

  const [colors, setColors] = useState(allColors);
  const [typestyles, setTypestyles] = useState(allTypestyles);
  const [fonts, setFonts] = useState(allFonts);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getFontById = (id: string) => {
    return fonts.find((font) => font._id === id);
  };

  const contextProps = useMemo(
    () => ({
      colors,
      setColors,
      typestyles,
      setTypestyles,
      fonts,
      setFonts,
      isAuthenticated,
      setIsAuthenticated,
      getFontById
    }),
    [
      colors,
      setColors,
      typestyles,
      setTypestyles,
      fonts,
      setFonts,
      isAuthenticated,
      setIsAuthenticated,
      getFontById
    ]
  );

  return (
    <ASGContext.Provider value={contextProps}>{children}</ASGContext.Provider>
  );
};

ASGProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ASGProvider;
