import React, { useEffect, useRef, useState } from 'react';
import { IDesignPrinciple } from '@livewire/sanity';
import cn from 'classnames';
import { useBreakpoints } from '@livewire/common/hooks';
import { LayoutProvider, TextScrambler } from '@livewire/common/components';
import * as styles from './styles.module.scss';

const DesignPrinciple = ({ hoverColor, tags, title }: IDesignPrinciple) => {
  const [isActive, setIsActive] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);

  const { smallDesktop } = useBreakpoints();

  const titleRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (smallDesktop) return;
    setIsActive((prev) => !prev);
  };

  const handleMouseEnter = () => {
    if (!smallDesktop) return;
    setIsActive(true);
  };

  const handleMouseMove = () => {
    if (!smallDesktop) return;
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    if (!smallDesktop) return;
    setIsActive(false);
  };

  const tagTransitionDelaySeconds = (index: number) => {
    const delayAmount = 0.05;

    if (isActive) {
      return index * delayAmount;
    }

    const totalDelay = tags.length * delayAmount;

    return totalDelay - index * delayAmount;
  };

  const calculateTitleHeight = () => {
    const updatedTitleHeight =
      titleRef.current?.getBoundingClientRect().height || 0;

    setTitleHeight(updatedTitleHeight);
  };

  useEffect(() => {
    calculateTitleHeight();

    window.addEventListener('resize', calculateTitleHeight);
    return () => window.removeEventListener('resize', calculateTitleHeight);
  }, []);

  useEffect(() => {
    if (smallDesktop) {
      setIsActive(false);
    }
  }, [smallDesktop]);

  const titleContainerHeight =
    isActive && !smallDesktop ? titleHeight + 40 : titleHeight;

  const titleColor = isActive ? hoverColor.hex : 'var(--color-black)';

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      className={cn(styles.container, { [styles.active]: isActive })}
    >
      <LayoutProvider maxWidth>
        <div className={styles.content}>
          <div className={cn('b1', styles.tags)}>
            {tags.map((tag, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: hoverColor.hex,
                  transitionDelay: `${tagTransitionDelaySeconds(i)}s`
                }}
                className={styles.tag}
              >
                {tag}
              </div>
            ))}
          </div>

          <div
            className={cn(styles.titleContainer, {
              [styles.mounted]: titleHeight
            })}
            style={{ height: titleContainerHeight }}
          >
            <div
              ref={titleRef}
              style={{ color: titleColor }}
              className={styles.title}
            >
              <TextScrambler trigger={isActive} text={title} />
            </div>
          </div>

          <div className={cn('label', styles.clickLabel)}>Click</div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default DesignPrinciple;
