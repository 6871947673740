// extracted by mini-css-extract-plugin
export var container = "styles-module--container--99bca";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--5418d";
export var error = "styles-module--error--cd11f";
export var fileUpload = "styles-module--fileUpload--464bc";
export var giant = "2200px";
export var hint = "styles-module--hint--583c2";
export var icon = "styles-module--icon--7b81b";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var topText = "styles-module--topText--04fd1";
export var warning = "styles-module--warning--98b29";